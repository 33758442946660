





























































import { Vue, Component, Watch, Inject, Ref } from "vue-property-decorator";
import DocumentViewer from "@/components/Managements/Document/DocumentViewer.vue";
import { ItemWrapGrid, ContextMenu, FileDropAreaCompact, ConfirmDialog, ConfirmDialogContent } from "ui-gallery";
import { Document } from "../../../models/documents/Document";
import { DocumentPage } from "../../../models/documents/DocumentPage";
import { animate } from "@/models/utilities/animate";
import { container } from "tsyringe";
import { DocumentManagementsService } from "@/models/documents/DocumentManagementsService";
import { DateTime } from "luxon";
import { delay } from "../../../models/utilities/timer";
import { from } from "linq";
import InputPasswordDialog from "@/components/Commons/InputPasswordDialog.vue";
import InputNewPasswordDialog from "@/components/Commons/InputNewPasswordDialog.vue";
import { stringify } from "qs";

/**
 * 資料設定ページを提供します.
 */
@Component({ components: { InputPasswordDialog, InputNewPasswordDialog, ConfirmDialog, DocumentViewer, FileDropAreaCompact, ItemWrapGrid, ContextMenu } })
export default class DocumentSettingPage extends Vue {
    private document: Document<DocumentPage> | null = null;
    private readonly documentManagementsService = container.resolve(DocumentManagementsService);
    private isLoading = false;

    @Ref()
    private inputNewPasswordDialog!: InputNewPasswordDialog;

    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => Promise<void>;

    private get activeGroup(): any | null {
        return this.documentManagementsService.activeGroup;
    }

    private async activated() {
        this.init();
    }

    /**
     * コンポーネントが作成されたときに実行されます．
     */
    private async init(): Promise<void> {
        this.isLoading = true;
        const dgId = this.$route.params.id;
        await this.documentManagementsService.fetchDocumentGroupsAsync();
        if (dgId) {
            // URLパラメータのグループをアクティブに
            const group = from(this.documentManagementsService.documentGroups).where(x => x.dgId === dgId).firstOrDefault();
            if (group) {
                this.documentManagementsService.activeGroup = group;
            }
        }
        else {
            if (this.documentManagementsService.activeGroup) {
                this.$router.replace("/document/" + this.documentManagementsService.activeGroup.dgId);
            }
        }
        this.isLoading = false;
    }

    /**
     * アクティブなグループが変更されたとき、URLパラメータを変更します．
     */
    private onActiveGroupChanged(group: any) {
        if (group.dgId !== this.$route.params.id) {
            this.$router.replace("/document/" + group.dgId);
        }
        this.documentManagementsService.activeGroup = group;
    }

    /**
     * 更新します．
     */
    private async onReloadRequested(): Promise<void> {
        this.isLoading = true;
        await this.documentManagementsService.fetchDocumentGroupsAsync();
        this.isLoading = false;
    }

    /**
     * DOMが構築されたときに実行されます．
     */
    private async mounted() {
        await animate(this.$el as HTMLElement, "opacity", "0", 0, 0);
        await animate(this.$el as HTMLElement, "opacity", "1", 0.5, 0);
    }

    /**
     * 一覧の資料が選択されたときに実行されます．
     */
    private async documentSelected(doc: Document<DocumentPage>): Promise<void> {
        this.isLoading = true;
        const fetched = await this.documentManagementsService.fetchDocumentAsync(doc.docId);
        this.isLoading = false;
        if (fetched) {
            this.document = fetched;
        }
    }

    /**
     * 資料を削除します．
     * @param docId 削除する資料ID
     */
    private async onRemoveDocument(docId: string): Promise<void> {
        this.isLoading = true;
        const isSuccess = await this.documentManagementsService.removeDocumentAsync(docId);
        this.documentManagementsService.fetchDocumentGroupsAsync();
        if (isSuccess) {
            this.notify("資料を削除しました", 4000);
        }
        else {
            this.notify("資料の削除に失敗しました", 4000, "error");
        }
        this.isLoading = false;
    }

    /**
     * ファイルのアップロードリクエストが発火されたとき．
     * @param file アップロードするファイル
     */
    private async onUploadRequested(file: File, dgId: string, isSetPassword: boolean): Promise<void> {
        try {
            if (isSetPassword) {
                const result = await this.inputNewPasswordDialog.showAsync() as { oldPassword: string; newPassword: string };
                this.isLoading = true;
                await this.documentManagementsService.uploadFileAsync(file, dgId, result.newPassword);
            }
            else {
                this.isLoading = true;
                const isSuccess = await this.documentManagementsService.uploadFileAsync(file, dgId, "");
            }
            await delay(1000);
            await this.documentManagementsService.fetchDocumentGroupsAsync();
            this.notify("資料をアップロードしました", 4000);
        }
        catch (ex) {
            this.notify(ex.message, 4000, "error");
            console.error(ex);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * 資料を公開します．
     * @param docId 資料ID
     * @param password パスワード
     */
    private async onChangePasswordRequested(docId: string): Promise<void> {
        const InputPasswordDialog = this.$refs.InputPasswordDialog as InputPasswordDialog;
        const result = await InputPasswordDialog.showAsync() as { oldPassword: string; newPassword: string };
        if (result) {
            this.isLoading = true;
            const isSuccess = await this.documentManagementsService.changePasswordAsync(docId, result.oldPassword, result.newPassword);
            await this.documentManagementsService.fetchDocumentGroupsAsync();
            if (isSuccess) {
                this.notify("パスワードを変更しました", 4000);
            }
            else {
                this.notify("パスワードの変更に失敗しました", 4000, "error");
            }
            this.isLoading = false;
        }
    }

    /**
     * フォルダの新規作成リクエストが発火されたとき．
     * @param file アップロードするファイル
     */
    private async onCreateNewGroupRequested(name: string): Promise<void> {
        this.isLoading = true;
        const isSuccess = await this.documentManagementsService.createNewGroupAsync(name);
        await this.documentManagementsService.fetchDocumentGroupsAsync();
        if (isSuccess) {
            this.notify("フォルダを作成しました", 4000);
        }
        else {
            this.notify("フォルダを作成に失敗しました", 4000, "error");
        }
        this.isLoading = false;
    }

    /**
     * グループを削除します．
     */
    private async onRemoveGroupRequested(dgId: string): Promise<void> {
        this.isLoading = true;
        const isSuccess = await this.documentManagementsService.removeGroup(dgId);
        this.documentManagementsService.activeGroup = null;
        await this.documentManagementsService.fetchDocumentGroupsAsync();
        if (isSuccess) {
            this.notify("フォルダを削除しました", 4000);
        }
        else {
            this.notify("フォルダの削除に失敗しました", 4000, "error");
        }
        this.isLoading = false;
    }

    /**
     * フォルダ名を変更します．
     */
    private async onRenameGroupRequested(dgId: string, name: string) {
        this.isLoading = true;
        const isSuccess = await this.documentManagementsService.renameGroup(dgId, name);
        if (isSuccess) {
            this.notify("フォルダ名を変更しました", 4000);
        }
        else {
            this.notify("フォルダ名の変更に失敗しました", 4000, "error");
        }
        this.isLoading = false;
    }

    /**
     * 資料名を変更します．
     */
    private async onRenameDocumentRequested(docId: string, name: string): Promise<void> {
        this.isLoading = true;
        const isSuccess = await this.documentManagementsService.renameDocument(docId, name);
        if (isSuccess) {
            this.notify("資料名を変更しました", 4000);
        }
        else {
            this.notify("資料名の変更に失敗しました", 4000, "error");
        }
        this.isLoading = false;
    }

    /**
     * 資料編集リクエストが発火したとき．
     * @param doc 編集する資料
     */
    private onEditRequested(doc: Document<DocumentPage>) {
        this.document = null;
        if (this.documentManagementsService.activeGroup) {
            this.$router.push("/document/" + this.documentManagementsService.activeGroup.dgId + "/edit/" + doc.docId);
        }
        else {
            throw new Error("activegroup is null");
        }
    }
}
