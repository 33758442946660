































import { Component, Watch, Vue, Mixins, Inject } from "vue-property-decorator";
import { DialogBase } from "ui-gallery";

/**
 * パスワードの再設定ダイアログを提供します．．
 */
@Component({ components: {} })
export default class InputNewPasswordDialog extends Mixins(DialogBase) {
    newPassword = "";
    confirmPassword = "";
    oldPassword = "";

    @Watch("isShow")
    private onIsShowChanged() {
        this.newPassword = "";
        this.confirmPassword = "";
        this.oldPassword = "";
    }

    private ok() {
        if (this.newPassword === this.confirmPassword) {
            this.close({
                newPassword: this.newPassword,
                oldPassword: this.oldPassword
            });
        }
    }

    private cancel() {
        this.close(undefined);
    }
}
