
































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Document } from "@/models/documents/Document";
import { DocumentPage } from "@/models/documents/DocumentPage";
import { animate } from "@/models/utilities/animate";
import { AuthService } from "@/models/auth/AuthService";
import { container } from "tsyringe";
import { config } from "@/config";

/**
 * 資料設定ページを提供します.
 */
@Component({ components: {} })
export default class DocumentViewer extends Vue {
    private selected = 1;
    private fitType: "cover" | "contain" = "contain";
    private isLoading = false;
    private readonly authService = container.resolve(AuthService);
    private config = config;

    @Prop({ default: () => new Document() }) readonly document !: Document<DocumentPage>;

    private get token() {
        return this.authService.token;
    }

    private get selectedPage() {
        return this.document.pages[this.selected - 1];
    }

    onErrorImg(e: Error) {
        console.log(e);
    }

    mounted() {
        this.isLoading = true;
    }

    /**
     * 現在選択中のページ番号
     */
    private get currentPageNumber(): string {
        return this.selected.toString();
    }
    private set currentPageNumber(i: string) {
        if (!i) return;
        const _i = Number(i);
        if (_i) {
            this.setSelectedWithAnimation(Math.max(1, Math.min(_i, this.document.pages.length)));
        }
    }

    private async setSelectedWithAnimation(index: number) {
        const el = this.$refs.viewer as HTMLElement | undefined;
        if (el) {
            const tmp = this.selected;
            if (this.selected !== tmp) return;
            this.selected = index;
        }
    }

    private onImgLoad(e: any) {
        console.log(e);
        this.isLoading = false;
    }

    /**
     * 前のページへ移動します.
     */
    private previousPage() {
        this.isLoading = true;
        this.setSelectedWithAnimation(Math.max(1, --this.selected));
    }

    /**
     * 次のページへ移動します.
     */
    private nextPage() {
        this.isLoading = true;
        this.setSelectedWithAnimation(Math.min(++this.selected, this.document.pages.length));
    }
}
